<template>
  <!-- <avue-form
    v-model="examineForm"
    ref="examineForm"
    :option="examineOption"
  ></avue-form> -->
  <div>
    <van-field
      v-model="examineForm.comment"
      rows="7"
      autosize
      label=""
      type="textarea"
      placeholder="请输入审批意见"
    />
    <!-- 推荐回复 -->
    <div class="recommendBox">
      <div class="txt">推荐回复</div>
      <div class="btns">
        <div :key="item" @click="btnsClick(item)" v-for="item in btns3">
          {{ item }}
        </div>
      </div>
    </div>
    <div class="attachment">
      <van-uploader
        :preview-image="false"
        v-model="fileList"
        :after-read="afterRead"
        :max-count="1"
      >
        <div class="uploader">
          <img
            src="../../../../../assets/images/attachment.png"
            alt=""
            srcset=""
          />
          <span>附件上传</span>
        </div>
      </van-uploader>
      <van-loading v-if="isuploader" size="24px">上传中...</van-loading>
      <div v-if="originalName" class="uploader uploader2">
        <div class="imgs">
          <img
            src="../../../../../assets/images/attachment.png"
            alt=""
            srcset=""
          />
          <span>{{ originalName }}</span>
        </div>
        <div class="imgs">
          <img
            src="../../../../../assets/images/download.png"
            alt=""
            srcset=""
            width="20"
            height="20"
            @click="download"
          />
          <span @click="delClick" class="del">删除</span>
        </div>
      </div>
    </div>
    <div class="approval">
      <span class="yuan"></span>
      <span style="color: #999">审批意见信息转发给</span>
      <span class="name"></span>
    </div>
  </div>
</template>

<script>
import request from "@/router/axios";
export default {
  name: "wf-exam-form",
  props: {
    process: {
      type: Object,
      default: () => {
        return {};
      },
    },
    rejectTitle: {
      type: String,
      default: "",
    },
  },
  watch: {
    process: {
      handler(val) {
        if (!val) return;
        const {
          hideComment,
          hideAttachment,
          hideCopy,
          hideExamine,
          copyUser,
          copyUserName,
        } = val;
        if (hideComment)
          this.findObject(this.examineOption.column, "comment").display = false; // 隐藏评论
        if (hideComment || hideAttachment)
          this.findObject(
            this.examineOption.column,
            "attachment"
          ).display = false; // 隐藏评论附件
        if (hideCopy)
          this.findObject(
            this.examineOption.column,
            "$copyUser"
          ).display = false; // 隐藏抄送人
        if (hideExamine)
          this.findObject(
            this.examineOption.column,
            "$assignee"
          ).display = false; // 隐藏指定下一步审核人
        if (copyUser) this.$set(this.examineForm, "copyUser", val.copyUser); // 默认抄送人
        if (copyUserName)
          this.$set(this.examineForm, "$copyUser", val.copyUserName); // 默认抄送人
      },
      deep: true,
      immediate: true,
    },
    rejectTitle: {
      handler(val) {
        this.examineForm.comment = "";
        this.fileList = [];
        this.originalName = "";
        if (val == "确认拒绝") {
          this.btns3 = this.btns2;
        } else {
          this.btns3 = this.btns;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      isuploader: false,
      btns: [
        "同意",
        "确认",
        "OK",
        "情况属实",
        "已审核",
        "好的",
        "通过",
        "已核实",
        "可以",
      ],
      btns2: [
        "拒绝",
        "不同意",
        "取消",
        "重复提交",
        "未解决",
        "作废",
        "时间不对",
        "请补充材料",
      ],
      btns3: [],
      fileList: [],
      examineForm: {
        comment: "",
        attachment: "",
      },
      originalName: "",
      fileUrl: "",
      examineOption: {
        menuBtn: false,
        labelWidth: 110,
        labelPosition: "right",
        column: [
          {
            label: "",
            placeholder: "请输入批复意见",
            prop: "comment",
            type: "textarea",
            span: 16,
            event: {
              change: (val) => {
                this.$emit("update:comment", val);
              },
            },
            display: true,
          },
          //   {
          //   label: '附件',
          //   prop: 'attachment',
          //   type: 'upload',
          //   propsHttp: {
          //     res: 'data',
          //     url: 'link',
          //     name: 'originalName'
          //   },
          //   props: {
          //     label: 'name',
          //     value: 'url'
          //   },
          //   action: '/api/sinoma-resource/oss/endpoint/put-file',
          //   span: 24,
          //   display: true
          // }, {
          //   label: '抄送人',
          //   prop: '$copyUser',
          //   placeholder: '请选择 抄送人',
          //   readonly: true,
          //   append: '+',
          //   span: 24,
          //   event: {
          //     click: () => {
          //       this.$emit('user-select', { type: 'copy', checkType: 'checkbox' })
          //     }
          //   },
          //   display: true
          // }, {
          //   label: '指定审批人',
          //   prop: '$assignee',
          //   placeholder: '指定下一级审批人，如不选择则使用默认处理人，驳回时无效。多选时若下一节点为多实例并行则会进行动态加减签，若不是则只有第一个生效。',
          //   readonly: true,
          //   append: '+',
          //   span: 24,
          //   event: {
          //     click: () => {
          //       this.$emit('user-select', { type: 'assignee', checkType: 'checkbox' })
          //     }
          //   },
          //   display: true
          // }
        ],
      },
    };
  },
  mounted() {},
  methods: {
    //
    delClick() {
      this.fileList = [];
      this.examineForm.attachment = "";
      this.originalName = "";
    },
    download() {
      // window.location.href = ''
      window.open(this.examineForm.attachment);
    },
    // 上传事件  /api/sinoma-resource/oss/endpoint/put-file
    afterRead(file) {
      this.isuploader = true;
      try {
        let that = this;
        let formData = new FormData();
        formData.append("file", file.file);
        request({
          url: "/api/sinoma-resource/oss/endpoint/put-file",
          method: "post",
          data: formData,
        }).then((res) => {
          this.isuploader = false;
          if (res.data.code == 200) {
            that.examineForm.attachment = res.data.data.link;
            that.originalName = res.data.data.originalName;
            that.fileUrl = res.data.data.link;
          }
        });
      } catch (error) {
        this.isuploader = false;
      }
    },
    getComment() {
      return this.examineForm.comment;
    },
    btnsClick(val) {
      this.examineForm.comment = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.recommendBox {
  margin: 0 10px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
  .txt {
    font-size: 14px;
    color: #999;
  }
  .btns {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    div {
      padding: 5px 10px;
      font-size: 14px;
      color: #000;
      background: #eaeeef;
      margin-bottom: 10px;
      margin-right: 10px;
      border-radius: 3px;
    }
  }
}
.attachment {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;

  .uploader {
    padding: 10px;
    width: 100%;
    font-size: 14px;
    color: #212121;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  .uploader2 {
    justify-content: space-between;
    background: #efefef;
    .imgs {
      display: flex;
      align-items: center;
    }
    .del {
      color: #d6000f;
      font-size: 12px;
      margin-left: 10px;
    }
  }
}
.approval {
  margin: 0 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
  .yuan {
    display: inline-block;
    background: #086de5;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;
    margin-right: 10px;
  }
  .yuan::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 1px solid #086de5;
    border-radius: 50%;
    position: absolute;
    top: -2px;
    left: -2px;
  }
  .name {
    margin-left: 10px;
    color: #086de5;
    font-weight: 600;
  }
}
</style>
