var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    { staticStyle: { height: "100vh" } },
    [
      _c("van-nav-bar", {
        staticClass: "van-nav-bar",
        attrs: { title: _vm.process.processDefinitionName },
      }),
      _c(
        "div",
        { staticClass: "statusIcon" },
        [
          _vm.process.processIsFinished === "reject"
            ? [
                _vm.process.status === "todo"
                  ? _c("i", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/statusIcon/audit.png"),
                          alt: "",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.process.status === "done"
                  ? _c("i", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/statusIcon/refuse.png"),
                          alt: "",
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.process.processIsFinished === "unfinished"
            ? [
                _vm.process.status === "todo"
                  ? _c("i", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/statusIcon/audit.png"),
                          alt: "",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.process.status === "done"
                  ? _c("i", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/statusIcon/processed.png"),
                          alt: "",
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.process.processIsFinished === "finished"
            ? [
                _vm.process.status === "todo"
                  ? _c("i", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/statusIcon/audit.png"),
                          alt: "",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.process.status === "done"
                  ? _c("i", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/statusIcon/pass.png"),
                          alt: "",
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "mainBox" },
        [
          _c(
            "van-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "van-collapse-item",
                {
                  attrs: {
                    title: _vm.$t(`cip.desk.workflow.title.indexHeadFirst`),
                    name: "1",
                  },
                },
                [
                  _c("div", { staticClass: "value" }, [
                    _c("iframe", {
                      ref: "iframeForm",
                      staticClass: "build_matrix_box",
                      staticStyle: {
                        width: "1920px",
                        height: "calc(100vh - 185px)",
                      },
                      attrs: {
                        id: "iframeForm",
                        src: _vm.iframe_url,
                        frameborder: "0",
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "van-collapse-item",
                {
                  attrs: {
                    title: _vm.$t(`cip.desk.workflow.title.indexHeadSecond`),
                    name: "2",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "value2" },
                    [_c("wf-flow", { attrs: { flow: _vm.flow } })],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "none" } },
        [
          _c(
            "van-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "van-tab",
                {
                  attrs: {
                    name: "first",
                    title: _vm.$t(`cip.desk.workflow.title.indexHeadFirst`),
                  },
                },
                [
                  _c(
                    "el-card",
                    { staticStyle: {}, attrs: { shadow: "never" } },
                    [
                      _c(
                        "div",
                        {
                          ref: "container",
                          staticStyle: { overflow: "scroll" },
                          attrs: { id: "printBody" },
                        },
                        [
                          _c("iframe", {
                            ref: "iframeForm",
                            staticClass: "build_matrix_box",
                            staticStyle: {
                              width: "1920px",
                              height: "calc(100vh - 185px)",
                            },
                            attrs: {
                              id: "iframeForm",
                              src: _vm.iframe_url,
                              frameborder: "0",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm.process.status == "todo"
                    ? _c(
                        "el-card",
                        {
                          staticStyle: {
                            "margin-top": "20px",
                            display: "none",
                          },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c("wf-examine-form", {
                            ref: "examineForm",
                            attrs: {
                              comment: _vm.comment,
                              process: _vm.process,
                            },
                            on: {
                              "update:comment": function ($event) {
                                _vm.comment = $event
                              },
                              "user-select": _vm.handleUserSelect,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "van-tab",
                {
                  attrs: {
                    name: "second",
                    title: _vm.$t(`cip.desk.workflow.title.indexHeadSecond`),
                  },
                },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "exchangeCard",
                      staticStyle: { padding: "10px 0" },
                      attrs: { shadow: "never" },
                    },
                    [_c("wf-flow", { attrs: { flow: _vm.flow } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "van-tab",
                {
                  attrs: {
                    name: "third",
                    title: _vm.$t(`cip.desk.workflow.title.indexHeadThird`),
                  },
                },
                [
                  _vm.activeName == "third"
                    ? [
                        _c(
                          "el-card",
                          {
                            staticClass: "exchangeCard",
                            staticStyle: { padding: "10px 0" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c("wf-design", {
                              ref: "bpmn",
                              staticStyle: { height: "500px" },
                              attrs: { options: _vm.bpmnOption },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "van-action-sheet",
        {
          attrs: { round: false, title: _vm.rejectTitle },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c("div", { staticClass: "dialog_content" }, [
            _c(
              "div",
              [
                _c("vant-wf-examine-form", {
                  ref: "examineForm",
                  attrs: {
                    comment: _vm.comment,
                    rejectTitle: _vm.rejectTitle,
                    process: _vm.process,
                  },
                  on: {
                    "update:comment": function ($event) {
                      _vm.comment = $event
                    },
                    "user-select": _vm.handleUserSelect,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "agreeBtn" }, [
              !_vm.isReject
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "agree",
                      on: {
                        click: function ($event) {
                          return _vm.handleExamineBefore(true)
                        },
                      },
                    },
                    [
                      ["recall", "reject"].includes(
                        _vm.process.processIsFinished
                      )
                        ? _c("span", [_vm._v("重新提交")])
                        : _c("span", [_vm._v("确认同意")]),
                    ]
                  )
                : _vm._e(),
              _vm.isReject
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "agree",
                      on: {
                        click: function ($event) {
                          return _vm.handleExamineBefore(false)
                        },
                      },
                    },
                    [_vm._v("\n          确认拒绝\n        ")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _c("vantButton", {
        attrs: {
          loading: _vm.submitLoading,
          "button-list": _vm.buttonList,
          process: _vm.process,
          comment: _vm.comment,
        },
        on: {
          showDialog: _vm.showDialogFun,
          examine: _vm.handleExamineBefore,
          "temporary-save": _vm.handleTemporary,
          "user-select": _vm.handleUserSelect,
          print: _vm.handlePrint,
          rollback: _vm.handleRollbackTask,
          terminate: _vm.handleTerminateProcess,
          withdraw: _vm.handleWithdrawTask,
        },
      }),
      _c("user-select", {
        ref: "user-select",
        attrs: {
          "check-type": _vm.checkType,
          "default-checked": _vm.defaultChecked,
        },
        on: { onConfirm: _vm.handleUserSelectConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }