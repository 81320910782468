<template>
  <div>
    <el-row
      class="foot-item"
      :style="{
        width:
          layoutType == 'functional'
            ? '100%'
            : isCollapse
            ? 'calc(100% - 68px)'
            : 'calc(100% - 0px)',
      }"
    >
      <template v-if="process.status == 'todo'">
        <!-- <van-button
          type="text"
          class="btnR el-button"
          style="color: black"
          @click="moreButtons = true"
        >
        </van-button> -->
        <div @click="moreButtons = true" class="moreBtn">
          <img
            class="dian"
            src="@/assets/images/newIcon3.png"
            alt=""
            srcset=""
          />
          <div class="moreTxt">更多</div>
        </div>

        <van-button
          class="clear btnR el-button"
          plain
          @click="toDoApplyHandle(true)"
          >拒绝</van-button
        >
        <van-button
          type="primary"
          class="btnR el-button"
          style="background-color: #0087ff"
          @click="toDoApplyHandle(false)"
          >同意</van-button
        >
        <!-- v-if="
            process.status == 'todo' &&
            buttonList.find((b) => b.buttonKey == 'wf_staging')
          " -->
        <van-button
          v-loading="loading"
          type="primary"
          class="btnR el-button"
          style="background-color: #0087ff"
          v-on:click="$emit('temporary-save', true)"
        >
          暂存</van-button
        >
        <!-- 确认同意 -->
      </template>
    </el-row>

    <el-dialog :visible.sync="nodeVisible" append-to-body title="选择回退节点">
      <avue-form
        v-if="nodeVisible"
        v-model="nodeForm"
        :option="nodeOption"
        @submit="handleNodeSubmit"
      ></avue-form>
    </el-dialog>

    <van-action-sheet :round="false" style="" v-model="moreButtons">
      <div class="moreBtns">
        <div class="topBox">
          <div
            class="button-icon"
            v-loading="loading"
            @click="
              $emit('user-select', { type: 'transfer', checkType: 'radio' })
            "
          >
            <img src="@/assets/appicon/2.png" />
            <span class="title">转办</span>
          </div>
          <div
            class="button-icon"
            type="primary"
            size="medium"
            v-loading="loading"
            @click="
              $emit('user-select', {
                type: 'addInstance',
                checkType: 'checkbox',
              })
            "
          >
            <img src="@/assets/appicon/33.png" />
            <!-- <img src="@/assets/appicon/2.png" /> -->
            <span class="title">加签</span>
          </div>
          <div
            class="button-icon"
            type="warning"
            size="medium"
            v-loading="loading"
            @click="
              $emit('user-select', { type: 'delegate', checkType: 'radio' })
            "
          >
            <img src="@/assets/appicon/5.png" />
            <span class="title">委托</span>
          </div>
          <div
            class="button-icon"
            type="danger"
            size="medium"
            v-loading="loading"
            @click="$emit('terminate')"
          >
            <img src="@/assets/appicon/6.png" />
            <span class="title">终止</span>
          </div>
        </div>
        <div class="topBox">
          <div
            class="button-icon"
            type="warning"
            size="medium"
            v-loading="loading"
            @click="$emit('withdraw')"
          >
            <img src="@/assets/appicon/7.png" />
            <div class="title">撤销</div>
          </div>
        </div>
      </div>
      <div class="close" @click="moreButtons = false">取消</div>
      <div style="display: none" class="dialog_content-button">
        <van-grid :column-num="4">
          <!--          <van-grid-item>-->
          <!--            <template>-->
          <!--              <div class="button-icon"-->
          <!--                         type="success"-->
          <!--                         size="medium"-->
          <!--                         v-loading="loading"-->
          <!--                         @click="$emit('temporary-save', true)">-->
          <!--                <img src="@/assets/appicon/1.png">-->
          <!--                <span class="title">暂存</span></div>-->
          <!--            </template>-->
          <!--          </van-grid-item>-->
          <van-grid-item>
            <!-- v-if="
              process.status == 'todo' &&
              buttonList.find((b) => b.buttonKey == 'wf_transfer')
            " -->
            <template>
              <div
                class="button-icon"
                v-loading="loading"
                @click="
                  $emit('user-select', { type: 'transfer', checkType: 'radio' })
                "
              >
                <img src="@/assets/appicon/2.png" />
                <span class="title">转办</span>
              </div>
            </template>
          </van-grid-item>
          <!-- v-if="
              process.status == 'todo' &&
              process.isMultiInstance &&
              buttonList.find((b) => b.buttonKey == 'wf_add_instance')
            " -->
          <van-grid-item>
            <template>
              <div
                class="button-icon"
                type="primary"
                size="medium"
                v-loading="loading"
                @click="
                  $emit('user-select', {
                    type: 'addInstance',
                    checkType: 'checkbox',
                  })
                "
              >
                <img src="../../../../../assets/images/icon_jia.png" />
                <span class="title">加签</span>
              </div>
            </template>
          </van-grid-item>
          <!-- v-if="
              process.status == 'todo' &&
              buttonList.find((b) => b.buttonKey == 'wf_delegate')
            " -->
          <van-grid-item>
            <template>
              <div
                class="button-icon"
                type="warning"
                size="medium"
                v-loading="loading"
                @click="
                  $emit('user-select', { type: 'delegate', checkType: 'radio' })
                "
              >
                <img src="@/assets/appicon/5.png" />
                <span class="title">委托</span>
              </div></template
            >
          </van-grid-item>
          <!-- v-if="
              process.status == 'todo' &&
              buttonList.find((b) => b.buttonKey == 'wf_terminate')
            " -->
          <van-grid-item>
            <template>
              <div
                class="button-icon"
                type="danger"
                size="medium"
                v-loading="loading"
                @click="$emit('terminate')"
              >
                <img src="@/assets/appicon/6.png" />
                <span class="title">终止</span>
              </div>
            </template>
          </van-grid-item>
          <!-- v-if="
              permission.wf_process_withdraw &&
              process.isOwner &&
              process.isReturnable &&
              !['recall', 'reject'].includes(process.processIsFinished)
            " -->
          <van-grid-item>
            <div
              class="button-icon"
              type="warning"
              size="medium"
              v-loading="loading"
              @click="$emit('withdraw')"
            >
              <img src="@/assets/appicon/7.png" />
              <span class="title">撤销</span>
            </div>
          </van-grid-item>
        </van-grid>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { backNodes } from "@/api/plugin/workflow/process";

import { mapGetters } from "vuex";

export default {
  name: "vantButton",
  computed: {
    ...mapGetters(["isCollapse", "permission", "layoutType"]),
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    buttonList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    process: Object,
    comment: String,
  },
  data() {
    return {
      nodeVisible: false,
      nodeForm: {},
      nodeOption: {
        column: [
          {
            label: "节点",
            prop: "nodeId",
            type: "select",
            props: {
              label: "nodeName",
              value: "nodeId",
            },
            span: 24,
            rules: [
              { required: true, message: "请选择回退节点", trigger: "change" },
            ],
          },
        ],
      },
      backNodes: [],

      taskForm: {
        approval: 1, //审批意见 0拒绝，1同意
        comment: "", //审批意见信息
        operator: "", //执行人名称
        operatorId: "", //执行人id
        taskId: "", //任务id
        taskInfo: "", //业务相关内容--签名图片url
      },
      rejectTitle: "",
      isReject: true,
      checked: true,
      showDialog: false,
      moreButtons: false,
    };
  },
  watch: {
    "process.taskId": {
      handler(val) {
        if (val) {
          backNodes({ taskId: val }).then((res) => {
            const list = res.data.data;
            this.backNodes = list;
            this.findObject(this.nodeOption.column, "nodeId").dicData = list;
          });
        }
      },
      immediate: true,
    },
    showDialog: {
      handler(newValue) {
        this.$refs.taskForm.resetFields();
      },
      immediate: true,
    },
  },
  methods: {
    toDoApplyHandle(type) {
      // console.log(type);
      this.isReject = type;
      this.rejectTitle = type ? "确认拒绝" : "确认同意";
      this.$emit("showDialog", this.isReject, this.rejectTitle);
    },
    approvalApply(type) {
      this.$emit("close", type);
    },
    cancelClick() {
      this.taskForm.taskInfo = "";
      this.signatureImg = "";
      this.taskForm.comment = "";
      this.moniIndex = 0;
      this.showDialog = false;
    },
    handleRollback() {
      // 指定回退
      if (!this.comment) {
        this.$message.error("请填写批复意见");
        return;
      }
      this.nodeVisible = true;
    },
    handleNodeSubmit() {
      // 指定回退确定
      const { nodeId } = this.nodeForm;
      this.$emit("rollback", nodeId);
    },
  },
};
</script>

<style lang="scss" scoped>
.foot-item {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 101;
  height: 66px;
  // background-color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-right: 10px;
  // -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .moreShow {
    span {
      display: block;
      font-size: 12px;
      margin: 5px 0;
    }
  }
}
.dialog_content {
  padding: 10px 30px 20px;
  .agreeBtn {
    box-sizing: border-box;

    bottom: 0;

    padding: 15px;
    /* margin-top: 15px; */
    /* padding-bottom: 20px; */
    > div {
      text-align: center;
      height: 40px;
      font-size: 15px;
      font-weight: 600;
      border-radius: 23px;
      line-height: 40px;
    }
    .reject {
      background: #f5faff;

      border: 1px solid #086de5;

      color: #086de5;
    }
    .agree {
      background: #086de5;
      color: #fff;
      border: 1px solid #086de5;
    }
  }
  .van-cell::after {
    border: none;
  }
  .opt_btns {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    > button {
      margin: 0 15px;
    }
  }
  .qz {
    margin-top: 5px;
    box-sizing: border-box;
    width: 85px;
    height: 40px;
    background: #086de5;
    border-radius: 20px;
    border: 1px solid #086de5;
    font-size: 13px;
    font-weight: 600;
    color: #ffffff;
  }
  .qz1 {
    background: #f5faff;
    border: 1px solid #086de5;
    color: #086de5;
  }
  .qzimg {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #d4e4f5;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .forward {
    margin-top: 14px;

    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #999999;

    span {
      margin-left: 15px;
    }
    .user {
      color: #086de5;
    }
  }
}
.van-action-sheet__header {
  font-size: 18px;

  font-weight: 600;
  color: #000000;
  .van-icon-cross:before {
    content: "\e6a7";
    color: #000000;
  }
}

.btnR {
  width: 65px;
  height: 30px;
  border-radius: 21px !important;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 10px;
  border: none;
}
.clear,
.clear:hover {
  background-color: #fff !important;
  color: #666 !important;
  border: 1px solid #ccc !important;
}

.el-button {
  margin: 0 5px;
}

.filIcon {
  width: 5px;
  height: 5px;
  display: inline-block;
  background-image: url("~@//assets/fileicon.png");
  background-size: 100px;
  background-repeat: no-repeat;
}

.dialog_content-button {
  .button-icon {
    text-align: center;
    img {
      width: 70px;
      height: 70px;
      display: block;
    }
    .title {
      display: block;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      -webkit-background-clip: text;
    }
  }
}
.moreBtn {
  width: 65px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #000000;
  .dian {
    width: 21px;
    height: 5px;
    font-size: 32px;
  }
  .moreTxt {
    width: 60px;
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
  }
}
.moreBtns {
  width: 100vw;
  background: #f2f2f2;
  padding: 20px 0;
  .topBox {
    display: flex;
    // justify-content: space-around;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .button-icon {
    text-align: center;
    width: 25%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
      margin-bottom: 5px;
    }
    .title {
      width: 60px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
}
.close {
  height: 40px;
  background: #ffffff;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
}
</style>
