var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-field", {
        attrs: {
          rows: "7",
          autosize: "",
          label: "",
          type: "textarea",
          placeholder: "请输入审批意见",
        },
        model: {
          value: _vm.examineForm.comment,
          callback: function ($$v) {
            _vm.$set(_vm.examineForm, "comment", $$v)
          },
          expression: "examineForm.comment",
        },
      }),
      _c("div", { staticClass: "recommendBox" }, [
        _c("div", { staticClass: "txt" }, [_vm._v("推荐回复")]),
        _c(
          "div",
          { staticClass: "btns" },
          _vm._l(_vm.btns3, function (item) {
            return _c(
              "div",
              {
                key: item,
                on: {
                  click: function ($event) {
                    return _vm.btnsClick(item)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(item) + "\n      ")]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "attachment" },
        [
          _c(
            "van-uploader",
            {
              attrs: {
                "preview-image": false,
                "after-read": _vm.afterRead,
                "max-count": 1,
              },
              model: {
                value: _vm.fileList,
                callback: function ($$v) {
                  _vm.fileList = $$v
                },
                expression: "fileList",
              },
            },
            [
              _c("div", { staticClass: "uploader" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../../assets/images/attachment.png"),
                    alt: "",
                    srcset: "",
                  },
                }),
                _c("span", [_vm._v("附件上传")]),
              ]),
            ]
          ),
          _vm.isuploader
            ? _c("van-loading", { attrs: { size: "24px" } }, [
                _vm._v("上传中..."),
              ])
            : _vm._e(),
          _vm.originalName
            ? _c("div", { staticClass: "uploader uploader2" }, [
                _c("div", { staticClass: "imgs" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../../../assets/images/attachment.png"),
                      alt: "",
                      srcset: "",
                    },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.originalName))]),
                ]),
                _c("div", { staticClass: "imgs" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../../../assets/images/download.png"),
                      alt: "",
                      srcset: "",
                      width: "20",
                      height: "20",
                    },
                    on: { click: _vm.download },
                  }),
                  _c(
                    "span",
                    { staticClass: "del", on: { click: _vm.delClick } },
                    [_vm._v("删除")]
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "approval" }, [
      _c("span", { staticClass: "yuan" }),
      _c("span", { staticStyle: { color: "#999" } }, [
        _vm._v("审批意见信息转发给"),
      ]),
      _c("span", { staticClass: "name" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }