var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        {
          staticClass: "foot-item",
          style: {
            width:
              _vm.layoutType == "functional"
                ? "100%"
                : _vm.isCollapse
                ? "calc(100% - 68px)"
                : "calc(100% - 0px)",
          },
        },
        [
          _vm.process.status == "todo"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "moreBtn",
                    on: {
                      click: function ($event) {
                        _vm.moreButtons = true
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "dian",
                      attrs: {
                        src: require("@/assets/images/newIcon3.png"),
                        alt: "",
                        srcset: "",
                      },
                    }),
                    _c("div", { staticClass: "moreTxt" }, [_vm._v("更多")]),
                  ]
                ),
                _c(
                  "van-button",
                  {
                    staticClass: "clear btnR el-button",
                    attrs: { plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.toDoApplyHandle(true)
                      },
                    },
                  },
                  [_vm._v("拒绝")]
                ),
                _c(
                  "van-button",
                  {
                    staticClass: "btnR el-button",
                    staticStyle: { "background-color": "#0087ff" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.toDoApplyHandle(false)
                      },
                    },
                  },
                  [_vm._v("同意")]
                ),
                _c(
                  "van-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "btnR el-button",
                    staticStyle: { "background-color": "#0087ff" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("temporary-save", true)
                      },
                    },
                  },
                  [_vm._v("\n        暂存")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.nodeVisible,
            "append-to-body": "",
            title: "选择回退节点",
          },
          on: {
            "update:visible": function ($event) {
              _vm.nodeVisible = $event
            },
          },
        },
        [
          _vm.nodeVisible
            ? _c("avue-form", {
                attrs: { option: _vm.nodeOption },
                on: { submit: _vm.handleNodeSubmit },
                model: {
                  value: _vm.nodeForm,
                  callback: function ($$v) {
                    _vm.nodeForm = $$v
                  },
                  expression: "nodeForm",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "van-action-sheet",
        {
          attrs: { round: false },
          model: {
            value: _vm.moreButtons,
            callback: function ($$v) {
              _vm.moreButtons = $$v
            },
            expression: "moreButtons",
          },
        },
        [
          _c("div", { staticClass: "moreBtns" }, [
            _c("div", { staticClass: "topBox" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "button-icon",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("user-select", {
                        type: "transfer",
                        checkType: "radio",
                      })
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/appicon/2.png") },
                  }),
                  _c("span", { staticClass: "title" }, [_vm._v("转办")]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "button-icon",
                  attrs: { type: "primary", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("user-select", {
                        type: "addInstance",
                        checkType: "checkbox",
                      })
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/appicon/33.png") },
                  }),
                  _c("span", { staticClass: "title" }, [_vm._v("加签")]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "button-icon",
                  attrs: { type: "warning", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("user-select", {
                        type: "delegate",
                        checkType: "radio",
                      })
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/appicon/5.png") },
                  }),
                  _c("span", { staticClass: "title" }, [_vm._v("委托")]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "button-icon",
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("terminate")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/appicon/6.png") },
                  }),
                  _c("span", { staticClass: "title" }, [_vm._v("终止")]),
                ]
              ),
            ]),
            _c("div", { staticClass: "topBox" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "button-icon",
                  attrs: { type: "warning", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("withdraw")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/appicon/7.png") },
                  }),
                  _c("div", { staticClass: "title" }, [_vm._v("撤销")]),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "close",
              on: {
                click: function ($event) {
                  _vm.moreButtons = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "div",
            {
              staticClass: "dialog_content-button",
              staticStyle: { display: "none" },
            },
            [
              _c(
                "van-grid",
                { attrs: { "column-num": 4 } },
                [
                  _c(
                    "van-grid-item",
                    [
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticClass: "button-icon",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("user-select", {
                                  type: "transfer",
                                  checkType: "radio",
                                })
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: { src: require("@/assets/appicon/2.png") },
                            }),
                            _c("span", { staticClass: "title" }, [
                              _vm._v("转办"),
                            ]),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                  _c(
                    "van-grid-item",
                    [
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticClass: "button-icon",
                            attrs: { type: "primary", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("user-select", {
                                  type: "addInstance",
                                  checkType: "checkbox",
                                })
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../../../../assets/images/icon_jia.png"),
                              },
                            }),
                            _c("span", { staticClass: "title" }, [
                              _vm._v("加签"),
                            ]),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                  _c(
                    "van-grid-item",
                    [
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticClass: "button-icon",
                            attrs: { type: "warning", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("user-select", {
                                  type: "delegate",
                                  checkType: "radio",
                                })
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: { src: require("@/assets/appicon/5.png") },
                            }),
                            _c("span", { staticClass: "title" }, [
                              _vm._v("委托"),
                            ]),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                  _c(
                    "van-grid-item",
                    [
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticClass: "button-icon",
                            attrs: { type: "danger", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("terminate")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: { src: require("@/assets/appicon/6.png") },
                            }),
                            _c("span", { staticClass: "title" }, [
                              _vm._v("终止"),
                            ]),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                  _c("van-grid-item", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass: "button-icon",
                        attrs: { type: "warning", size: "medium" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("withdraw")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: { src: require("@/assets/appicon/7.png") },
                        }),
                        _c("span", { staticClass: "title" }, [_vm._v("撤销")]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }